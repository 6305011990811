// src/context/ProductsContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Product, productsData as initialProductsData, Variation } from '../data/productsData';
import { v4 as uuidv4 } from 'uuid';

// Definindo a interface para o contexto
interface ProductsContextType {
  products: Product[];
  addProduct: (product: Omit<Product, 'productid' | 'variations'> & { variations: Omit<Variation, 'id'>[] }) => void;
  updateProduct: (updatedProduct: Product) => void;
  deleteProduct: (productid: string) => void;
}

const ProductsContext = createContext<ProductsContextType | undefined>(undefined);

// Provider do contexto
export const ProductsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>(initialProductsData);

  // Adicionar Produto
  const addProduct = (product: Omit<Product, 'productid' | 'variations'> & { variations: Omit<Variation, 'id'>[] }) => {
    const newProduct: Product = {
      ...product,
      productid: uuidv4(), // Gerar um ID único
      variations: product.variations.map(variation => ({
        ...variation,
        id: uuidv4(),
      })),
    };
    setProducts(prev => [...prev, newProduct]);
  };

  // Atualizar Produto
  const updateProduct = (updatedProduct: Product) => {
    setProducts(prev =>
      prev.map(product => (product.productid === updatedProduct.productid ? updatedProduct : product))
    );
  };

  // Deletar Produto
  const deleteProduct = (productid: string) => {
    setProducts(prev => prev.filter(product => product.productid !== productid));
  };

  return (
    <ProductsContext.Provider value={{ products, addProduct, updateProduct, deleteProduct }}>
      {children}
    </ProductsContext.Provider>
  );
};

// Hook para usar o contexto
export const useProducts = (): ProductsContextType => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error('useProducts deve ser usado dentro de um ProductsProvider');
  }
  return context;
};
