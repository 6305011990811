// src/pages/Home.tsx

import React from "react";
import HeroSection from "../components/sections/HeroSection";
import ScrollToTop from "../components/layout/ScrollToTop";

const Home: React.FC = () => {
  return (
    <div className="relative">
      <HeroSection />
      <ScrollToTop />
    </div>
  );
};

export default Home;