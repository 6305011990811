// src/components/manager/Manager.tsx

import React from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import ProductManagement from './ProductManagement';
import FreightManagement from './FreightManagement';
import LaborManagement from './LaborManagement';
import TaxManagement from './TaxManagement';

const Manager: React.FC = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Manager Dashboard
      </h1>
      <nav className="mb-6">
        <ul className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
          <li>
            <Link
              to="products"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300 text-center block"
            >
              Product Management
            </Link>
          </li>
          <li>
            <Link
              to="freight"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300 text-center block"
            >
              Freight Management
            </Link>
          </li>
          <li>
            <Link
              to="labor"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300 text-center block"
            >
              Labor Management
            </Link>
          </li>
          <li>
            <Link
              to="tax"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-300 text-center block"
            >
              Tax Management
            </Link>
          </li>
        </ul>
      </nav>
      <div className="pt-6">
        <Routes>
          <Route path="products" element={<ProductManagement />} />
          <Route path="freight" element={<FreightManagement />} />
          <Route path="labor" element={<LaborManagement />} />
          <Route path="tax" element={<TaxManagement />} />
          {/* Default route for Manager */}
          <Route path="/" element={<Navigate to="products" replace />} />
          {/* Catch-all route inside Manager */}
          <Route path="*" element={<p>Please select an option above.</p>} />
        </Routes>
      </div>
    </div>
  );
};

export default Manager;
