// src/components/ModeToggle.tsx

import React from "react";
import { useLocation } from "react-router-dom";
import withTintImg from "../../assets/images/withtint.webp";
import withoutTintImg from "../../assets/images/withouttint.webp";
import { useTheme } from "../../context/ThemeContext";

const ModeToggle = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const location = useLocation();

  if (location.pathname !== "/") {
    return null;
  }

  const bgColor = darkMode ? "bg-gray-800" : "bg-white";
  const accentColor = darkMode ? "#2563EB" /* Dark Blue */ : "#EA580C" /* Orange */;

  return (
    <div
      className={`relative flex items-center justify-center w-full h-screen overflow-hidden transition duration-500 ease-in-out ${bgColor}`}
    >
      <img
        src={darkMode ? withTintImg : withoutTintImg}
        alt={darkMode ? "Window with tint" : "Window without tint"}
        className={`absolute inset-0 w-full h-full object-cover`}
      />

      {!darkMode ? (
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-black p-4 bg-white bg-opacity-50">
          <h1 className="text-3xl font-bold mb-4">
            Is the glare affecting your comfort?
          </h1>
          <p className="text-lg mb-8">
            Click the button below to see the difference our tint can make.
          </p>
          <div className="animate-bounce">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8 mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-4 bg-black bg-opacity-50">
          <div className="p-4">
            <h1 className="text-3xl font-bold mb-4">
              Experience Enhanced Comfort and Protection
            </h1>
            <p className="text-lg">Our tint transforms your environment.</p>
          </div>
        </div>
      )}

      <button
        key={darkMode ? "dark" : "light"} // Add this line
        type="button"
        aria-label="Toggle Tint"
        onClick={toggleDarkMode}
        style={{
          borderColor: accentColor,
          color: accentColor,
          backgroundColor: "white",
          transition: "background-color 0.3s, color 0.3s",
        }}
        className="absolute bottom-8 p-4 border font-semibold"
        onMouseEnter={(e) => {
          const target = e.target as HTMLElement;
          target.style.backgroundColor = accentColor;
          target.style.color = "#ffffff";
        }}
        onMouseLeave={(e) => {
          const target = e.target as HTMLElement;
          target.style.backgroundColor = "white";
          target.style.color = accentColor;
        }}
      >
        {darkMode ? "See Before" : "Apply Tint"}
      </button>
    </div>
  );
};

export default ModeToggle;
