// src/App.tsx

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import ModeToggle from "./components/toggle/ModeToggle";
import SeparatorLine from "./components/layout/SeparatorLine";
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Manager from "./components/manager/Manager";
import Login from "./components/manager/Login";
import PrivateRoute from "./components/manager/PrivateRoute";
import { useTheme } from "./context/ThemeContext"; // Removed ThemeProvider
import "./index.css";

const App: React.FC = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

// Separate component to utilize hooks and state
const MainApp: React.FC = () => {
  const { darkMode } = useTheme();
  const bgColor = darkMode ? "bg-gray-900 text-white" : "bg-white text-black";

  // State to control user authentication
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    return storedAuth ? JSON.parse(storedAuth) : false;
  });

  // Function to handle login
  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  return (
    <div className={`min-h-screen ${bgColor} transition-colors duration-300`}>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      <SeparatorLine />
      <ModeToggle />

      <main>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Protected Route for Manager */}
          <Route
            path="/manager/*"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Manager />
              </PrivateRoute>
            }
          />

          {/* Redirect unknown routes to Home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
};

export default App;
