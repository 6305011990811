// src/context/LaborContext.tsx

import React, { createContext, useState, useContext } from 'react';
import { LaborData, laborData as initialLaborData } from '../data/laborData';

interface LaborContextProps {
  laborSettings: LaborData;
  updateLaborSettings: (settings: LaborData) => void;
}

const LaborContext = createContext<LaborContextProps | undefined>(undefined);

export const useLabor = () => {
  const context = useContext(LaborContext);
  if (!context) {
    throw new Error('useLabor must be used within a LaborProvider');
  }
  return context;
};

export const LaborProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [laborSettings, setLaborSettings] = useState<LaborData>(initialLaborData);

  const updateLaborSettings = (settings: LaborData) => {
    setLaborSettings(settings);
  };

  return (
    <LaborContext.Provider value={{ laborSettings, updateLaborSettings }}>
      {children}
    </LaborContext.Provider>
  );
};
