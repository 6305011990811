// src/data/taxData.ts

export interface TaxEntry {
  id: number;
  taxType: string; // e.g., "State Tax"
  rate: number;    // e.g., 0.07 for 7%
}

export const taxData: TaxEntry[] = [
  { id: 1, taxType: "State Tax", rate: 0.07 },
  { id: 2, taxType: "Federal Tax", rate: 0.05 },
  { id: 3, taxType: "Tax1", rate: 0.02 },
  // Add more entries as needed
];
