// src/components/manager/TaxManagement.tsx

import React, { useState, useEffect } from 'react';
import { TaxEntry, taxData as initialTaxData } from '../../data/taxData';

// Remove theme-based styling
// import { useStyledColors } from '../../hooks/useStyledColors';

const TaxManagement: React.FC = () => {
  const [taxes, setTaxes] = useState<TaxEntry[]>(initialTaxData);
  const [newTax, setNewTax] = useState<TaxEntry>({
    id: 0,
    taxType: '',
    rate: NaN,
  });

  const [editingId, setEditingId] = useState<number | null>(null);

  // State to handle validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (editingId !== null) {
      const taxToEdit = taxes.find((t) => t.id === editingId);
      if (taxToEdit) {
        setNewTax(taxToEdit);
      }
    } else {
      resetForm();
    }
    // Clear errors when switching entries
    setErrors({});
  }, [editingId, taxes]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setNewTax((prev) => {
      let updatedValue: string | number = value;

      if (name === 'rate') {
        // Replace commas with dots for decimal parsing
        const normalizedValue = value.replace(',', '.');

        // Parse the value to a float
        const parsedValue = parseFloat(normalizedValue);

        // Ensure the rate is not negative
        updatedValue = isNaN(parsedValue) || parsedValue < 0 ? NaN : parsedValue;
      }

      return {
        ...prev,
        [name]: updatedValue,
      };
    });

    // Clear error for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleAddOrUpdateTax = () => {
    const newErrors: { [key: string]: string } = {};

    if (newTax.taxType.trim() === '') {
      newErrors.taxType = 'Please enter a tax type.';
    }

    if (isNaN(newTax.rate) || newTax.rate <= 0) {
      newErrors.rate = 'Please enter a valid rate greater than 0.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (editingId !== null) {
      // Update existing tax entry
      setTaxes((prev) =>
        prev.map((t) => (t.id === editingId ? { ...newTax, id: t.id } : t))
      );
    } else {
      // Add new tax entry
      const newId = taxes.length > 0 ? Math.max(...taxes.map((t) => t.id)) + 1 : 1;
      setTaxes((prev) => [...prev, { ...newTax, id: newId }]);
    }
    resetForm();
    // Clear errors
    setErrors({});
  };

  const handleDeleteTax = (id: number) => {
    if (window.confirm('Are you sure you want to delete this tax entry?')) {
      setTaxes((prev) => prev.filter((t) => t.id !== id));
      if (editingId === id) {
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setNewTax({
      id: 0,
      taxType: '',
      rate: NaN,
    });
    setEditingId(null);
    setErrors({});
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
        Tax Management
      </h2>

      {/* Tax Form */}
      <div className="mb-6">
        <div className="flex flex-col space-y-4">
          {/* Tax Type */}
          <div>
            <label htmlFor="taxType" className="block mb-2 font-medium text-gray-700">
              Tax Type
            </label>
            <input
              type="text"
              name="taxType"
              id="taxType"
              placeholder="Enter tax type (e.g., State Tax)"
              value={newTax.taxType}
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.taxType ? 'border-red-500' : ''
              }`}
            />
            {errors.taxType && (
              <p className="text-red-500 text-sm mt-1">{errors.taxType}</p>
            )}
          </div>

          {/* Rate */}
          <div>
            <label htmlFor="rate" className="block mb-2 font-medium text-gray-700">
              Rate (%)
            </label>
            <input
              type="number"
              name="rate"
              id="rate"
              placeholder="Enter rate (e.g., 7 for 7%)"
              value={isNaN(newTax.rate) || newTax.rate === 0 ? '' : newTax.rate}
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.rate ? 'border-red-500' : ''
              }`}
              min="0"
              step="0.01"
            />
            {errors.rate && (
              <p className="text-red-500 text-sm mt-1">{errors.rate}</p>
            )}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center space-x-2 mt-6">
          <button
            onClick={handleAddOrUpdateTax}
            className="bg-green-500 text-white px-6 py-2 rounded"
          >
            {editingId !== null ? 'Update Tax' : 'Add Tax'}
          </button>
          {editingId !== null && (
            <button
              onClick={resetForm}
              className="bg-gray-500 text-white px-6 py-2 rounded"
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      {/* Existing Tax Entries */}
      <div>
        <h4 className="text-xl font-semibold mb-4 text-center text-gray-800">
          Existing Tax Entries
        </h4>
        <ul>
          {taxes.map((t) => (
            <li
              key={t.id}
              className="flex justify-between items-center border-b py-2"
            >
              <div className="text-gray-800">
                <strong>{t.taxType}</strong> - {t.rate.toFixed(2)}%
              </div>
              <div>
                <button
                  onClick={() => setEditingId(t.id)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteTax(t.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
          {taxes.length === 0 && (
            <li className="py-4 text-center text-gray-500">
              No tax entries available.
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TaxManagement;
