// src/components/manager/LaborManagement.tsx

import React, { useState, useEffect } from 'react';
import { useLabor } from '../../context/LaborContext';

// Remove theme-based styling
// import { useStyledColors } from '../../hooks/useStyledColors';

const LaborManagement: React.FC = () => {
  const { laborSettings, updateLaborSettings } = useLabor();

  const [editing, setEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState(laborSettings);

  useEffect(() => {
    setFormData(laborSettings);
  }, [laborSettings]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let parsedValue: number = parseFloat(value.replace(',', '.'));

    if (isNaN(parsedValue) || parsedValue < 0) {
      parsedValue = 0;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  const handleSave = () => {
    updateLaborSettings(formData);
    alert('Labor settings updated successfully!');
  };

  const handleCancel = () => {
    setFormData(laborSettings);
    setEditing(false);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
        Labor Management
      </h2>

      {/* Labor Form */}
      <div className="mb-6">
        <div className="flex flex-col space-y-4">
          {/* Base Labor Cost */}
          <div>
            <label htmlFor="baseLaborCost" className="block mb-2 font-medium text-gray-700">
              Base Labor Cost ($)
            </label>
            <input
              type="number"
              name="baseLaborCost"
              id="baseLaborCost"
              placeholder="Enter base labor cost"
              value={
                isNaN(formData.baseLaborCost) || formData.baseLaborCost === 0
                  ? ''
                  : formData.baseLaborCost
              }
              onChange={handleInputChange}
              className="border p-2 rounded-md w-full text-gray-800"
              min="0"
              step="0.01"
              required
            />
          </div>

          {/* Additional Labor Multiplier */}
          <div>
            <label
              htmlFor="additionalLaborMultiplier"
              className="block mb-2 font-medium text-gray-700"
            >
              Additional Labor Multiplier
            </label>
            <input
              type="number"
              name="additionalLaborMultiplier"
              id="additionalLaborMultiplier"
              placeholder="Enter additional labor multiplier"
              value={
                isNaN(formData.additionalLaborMultiplier) ||
                formData.additionalLaborMultiplier === 0
                  ? ''
                  : formData.additionalLaborMultiplier
              }
              onChange={handleInputChange}
              className="border p-2 rounded-md w-full text-gray-800"
              min="0"
              step="0.01"
              required
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-6 py-2 rounded"
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="bg-gray-500 text-white px-6 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>

      {/* Existing Labor Entries */}
      <div>
        <h4 className="text-xl font-semibold mb-4 text-center text-gray-800">
          Existing Labor Entries
        </h4>
        <div className="text-gray-800">
          <p className="mb-4">
            <strong>Base Labor Cost:</strong> ${laborSettings.baseLaborCost.toFixed(2)}
          </p>
          <p className="mb-4">
            <strong>Additional Labor Multiplier:</strong> {laborSettings.additionalLaborMultiplier}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LaborManagement;
