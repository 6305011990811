// src/hooks/useStyledColors.ts

import { useTheme } from "../context/ThemeContext";

export const useStyledColors = () => {
  const { darkMode } = useTheme();

  const bgColor = darkMode ? "bg-white text-black" : "bg-white text-black";
  const inputBgColor = darkMode
    ? "bg-white border-gray-300 text-black"
    : "bg-white border-gray-300 text-black";
  const buttonPrimaryBgColor = darkMode
    ? "bg-blue-600 hover:bg-blue-700 text-white"
    : "bg-orange-600 hover:bg-orange-700 text-white";
  const buttonSecondaryBgColor = darkMode
    ? "bg-gray-600 hover:bg-gray-700 text-white"
    : "bg-gray-300 hover:bg-gray-400 text-black";

  // Accent colors for highlights (uso de `style` necessário)
  const accentColor = darkMode ? "#2563EB" : "#EA580C"; // Use valores hexadecimais para evitar problemas
  const accentBgColor = darkMode ? "#2563EB" : "#EA580C"; // Mesma cor para o background do accent

  return {
    bgColor,
    inputBgColor,
    buttonPrimaryBgColor,
    buttonSecondaryBgColor,
    accentColor,
    accentBgColor, // Return the background accent color
    textColor: darkMode ? "text-black" : "text-black",
  };
};
