// src/components/HeroSection.tsx

import React from "react";
import { Link } from "react-router-dom";
import { useStyledColors } from "../../hooks/useStyledColors"; // Import the useStyledColors hook

const HeroSection = () => {
  const { accentColor, accentBgColor, buttonPrimaryBgColor, textColor } =
    useStyledColors(); // Use the hook to get the colors

  const benefits = [
    {
      title: "Enhanced Comfort",
      description:
        "Our films effectively reduce glare and heat, creating a more comfortable and visually appealing environment for everyone in your building. Say goodbye to the discomfort of harsh sunlight and excessive heat.",
    },
    {
      title: "Increased Productivity",
      description:
        "By minimizing distractions caused by glare and maintaining a consistent temperature, your employees can focus better, leading to increased efficiency and morale.",
    },
    {
      title: "Energy Efficiency",
      description:
        "Our films help regulate indoor temperatures, reducing the need for excessive air conditioning. This translates into lower energy costs and a smaller carbon footprint for your business.",
    },
    {
      title: "Privacy and Security",
      description:
        "Deflect films provide an added layer of privacy without sacrificing natural light, ensuring your business remains both secure and welcoming.",
    },
    {
      title: "Impressive Aesthetics",
      description:
        "Elevate the appearance of your building with sleek, modern window films that not only perform but also enhance the overall look of your property.",
    },
  ];

  return (
    <section
      className={`relative flex flex-col items-center justify-start min-h-screen text-center p-8 pt-8 sm:pt-12 bg-white ${textColor}`}
    >
      <h1 className="text-2xl sm:text-4xl font-bold mb-4">
        Experience Unmatched Comfort and Protection with Deflect
      </h1>
      <div
        className="h-1 w-24 mb-8"
        style={{ backgroundColor: accentBgColor }}
      ></div>
      <p className="text-lg mb-8 max-w-3xl leading-relaxed text-justify">
        At Deflect, we specialize in providing cutting-edge solar and
        temperature protection films for commercial buildings. Our mission is to
        enhance your workspace environment, boosting comfort and productivity.
      </p>
      <ul className="mb-8 max-w-3xl leading-relaxed text-justify">
        {benefits.map((benefit, index) => (
          <li key={index} className="mb-4">
            <span className="mr-2" style={{ color: accentColor }}>
              ➔
            </span>
            <strong>{benefit.title}:</strong> {benefit.description}
          </li>
        ))}
      </ul>
      <p className="text-lg mb-10 -mt-3 max-w-3xl leading-relaxed text-justify">
        Invest in your business today with Deflect and enjoy the perfect balance
        of light, comfort, and protection.
      </p>

      <Link to="/services">
        <button
          type="button"
          aria-label="Explore Our Products"
          style={{
            borderColor: accentColor,
            color: accentColor,
            backgroundColor: "white",
            transition: "background-color 0.3s, color 0.3s",
          }}
          className="mb-20 py-3 px-6 border font-semibold"
          onMouseEnter={(e) => {
            const target = e.target as HTMLElement;
            target.style.backgroundColor = accentColor;
            target.style.color = "#ffffff";
          }}
          onMouseLeave={(e) => {
            const target = e.target as HTMLElement;
            target.style.backgroundColor = "white";
            target.style.color = accentColor;
          }}
        >
          Explore Our Products
        </button>
      </Link>
    </section>
  );
};

export default HeroSection;
