// src/pages/Services.tsx

import React from 'react';
import ProductCard from '../components/product/ProductCard';
import { useStyledColors } from '../hooks/useStyledColors';
import { useProducts } from '../context/ProductsContext';
import ImageCarousel from '../components/Carousel';

const Services: React.FC = () => {
  const { accentBgColor, bgColor, textColor } = useStyledColors();
  const { products } = useProducts();

  // Filter only available products
  const availableProducts = products.filter((product) => product.available);

  return (
    <div className={`${bgColor} ${textColor}`}>
      {/* Header */}
      <div className="text-center mb-8 p-4 sm:p-8">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4">Our Products</h1>
        <div className="flex justify-center">
          <div
            className="h-1 w-16 sm:w-24 rounded"
            style={{ backgroundColor: accentBgColor }}
          ></div>
        </div>
      </div>

      {/* Carousel */}
      <div className="relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] w-screen">
        <ImageCarousel />
      </div>

      {/* Product List */}
      <div className="p-4 sm:p-8">
        <div className="-mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          {availableProducts.map((product) => (
            <ProductCard key={product.productid} product={product} />
          ))}
          {availableProducts.length === 0 && (
            <p className="text-center text-gray-500">
              No products available at the moment.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
