// src/components/manager/FreightManagement.tsx

import React, { useState, useEffect } from 'react';
import { FreightEntry, freightData as initialFreightData } from '../../data/freightData';

// Remove theme-based styling
// import { useStyledColors } from '../../hooks/useStyledColors';

const FreightManagement: React.FC = () => {
  const [freight, setFreight] = useState<FreightEntry[]>(initialFreightData);
  const [newFreight, setNewFreight] = useState<FreightEntry>({
    id: 0,
    distanceRange: '',
    price: 0,
  });

  const [editingId, setEditingId] = useState<number | null>(null);

  // State to handle validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (editingId !== null) {
      const freightToEdit = freight.find((f) => f.id === editingId);
      if (freightToEdit) {
        setNewFreight(freightToEdit);
      }
    } else {
      resetForm();
    }
  }, [editingId, freight]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setNewFreight((prev) => ({
      ...prev,
      [name]: name === 'price' ? parseFloat(value) : value,
    }));

    // Clear error for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleAddOrUpdateFreight = () => {
    const newErrors: { [key: string]: string } = {};

    if (newFreight.distanceRange.trim() === '') {
      newErrors.distanceRange = 'Please enter a distance range.';
    }

    if (isNaN(newFreight.price) || newFreight.price <= 0) {
      newErrors.price = 'Please enter a valid price greater than 0.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const freightEntry: FreightEntry = {
      id: editingId !== null ? editingId : Date.now(),
      distanceRange: newFreight.distanceRange.trim(),
      price: parseFloat(newFreight.price.toFixed(2)),
    };

    if (editingId !== null) {
      // Update existing freight entry
      setFreight((prev) =>
        prev.map((f) => (f.id === editingId ? freightEntry : f))
      );
      // Optionally display a success message
      // alert('Freight entry updated successfully!');
    } else {
      // Add new freight entry
      setFreight((prev) => [...prev, freightEntry]);
      // Optionally display a success message
      // alert('Freight entry added successfully!');
    }
    resetForm();
    // Clear errors
    setErrors({});
  };

  const handleDeleteFreight = (id: number) => {
    if (window.confirm('Are you sure you want to delete this freight entry?')) {
      setFreight((prev) => prev.filter((f) => f.id !== id));
      if (editingId === id) {
        resetForm();
      }
      // Optionally display a success message
      // alert('Freight entry deleted successfully!');
    }
  };

  const resetForm = () => {
    setNewFreight({
      id: 0,
      distanceRange: '',
      price: 0,
    });
    setEditingId(null);
    setErrors({});
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800">
        Freight Management
      </h3>

      {/* Freight Form */}
      <div className="mb-6">
        {/* Distance Range Input */}
        <div className="mb-4">
          <label htmlFor="distanceRange" className="block mb-2 font-medium text-gray-700">
            Distance Range
          </label>
          <input
            type="text"
            name="distanceRange"
            id="distanceRange"
            placeholder="e.g., 0-10 miles"
            value={newFreight.distanceRange}
            onChange={handleInputChange}
            className={`border p-2 rounded-md w-full text-gray-800 ${
              errors.distanceRange ? 'border-red-500' : ''
            }`}
            required
          />
          {errors.distanceRange && (
            <p className="text-red-500 text-sm mt-1">{errors.distanceRange}</p>
          )}
        </div>
        {/* Price Input */}
        <div className="mb-4">
          <label htmlFor="price" className="block mb-2 font-medium text-gray-700">
            Price ($)
          </label>
          <input
            type="number"
            name="price"
            id="price"
            placeholder="Enter price"
            value={
              isNaN(newFreight.price) || newFreight.price === 0 ? '' : newFreight.price
            }
            onChange={handleInputChange}
            className={`border p-2 rounded-md w-full text-gray-800 ${
              errors.price ? 'border-red-500' : ''
            }`}
            min="0"
            step="0.01"
            required
          />
          {errors.price && (
            <p className="text-red-500 text-sm mt-1">{errors.price}</p>
          )}
        </div>
        {/* Action Buttons */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handleAddOrUpdateFreight}
            className="bg-green-500 text-white px-6 py-2 rounded"
          >
            {editingId !== null ? 'Update Freight' : 'Add Freight'}
          </button>
          {editingId !== null && (
            <button
              onClick={resetForm}
              className="bg-gray-500 text-white px-6 py-2 rounded"
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      {/* Freight List */}
      <div>
        <h4 className="text-xl font-semibold mb-4 text-center text-gray-800">
          Existing Freight Entries
        </h4>
        <ul>
          {freight.map((f) => (
            <li
              key={f.id}
              className="flex justify-between items-center border-b py-2"
            >
              <div className="text-gray-800">
                <strong>{f.distanceRange}</strong> - ${f.price.toFixed(2)}
              </div>
              <div>
                <button
                  onClick={() => setEditingId(f.id)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteFreight(f.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
          {freight.length === 0 && (
            <li className="py-4 text-center text-gray-500">
              No freight entries available.
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FreightManagement;
