// src/data/productsData.ts

import buildingTintWindowShield from "../assets/images/building_tint_window_shield.webp";
import shatterproofFilmForGlassWindows from "../assets/images/shatterproof_film_for_glass_windows.webp";
import reflectiveBuildingTintFilm from "../assets/images/reflective_building_tint_film.webp";
import charcoal2PlyNanoCeramicVltTint from "../assets/images/charcoal_2ply_nano_ceramic_vlt_tint.webp";
import { v4 as uuidv4 } from 'uuid'; // Importar uuidv4

export interface Variation {
  id: string;
  color: string;
  size: string;
  available: boolean;
}

export interface Product {
  productid: string;
  name: string;
  baseprice: number;
  description: string;
  image: string;
  characteristics: string[];
  variations: Variation[];
  available: boolean;
}

export const productsData: Product[] = [
  {
    productid: "1",
    name: "Building Tint Window Shield",
    baseprice: 150,
    description:
      "High-quality reflective daytime privacy film designed to enhance the comfort and energy efficiency of commercial buildings. It provides superior heat blocking, UV protection, and adds a sleek aesthetic to your office or business facade.",
    image: buildingTintWindowShield,
    characteristics: [
      "Reflective daytime privacy",
      "Reduces heat and glare",
      "Blocks up to 99% UV rays",
      "Enhances building appearance",
      "Energy cost savings",
    ],
    variations: [
      { id: uuidv4(), color: "Black-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Blue-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Gold-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Green-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Tea-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Total-Blackout", size: "36 x 25 FT", available: false },
    ],
    available: true,
  },
  {
    productid: "2",
    name: "Shatterproof Film for Glass Windows",
    baseprice: 200,
    description:
      "Premium security and safety film that reinforces glass windows, preventing shattering upon impact. Ideal for commercial buildings seeking enhanced security, UV protection, and indoor temperature regulation.",
    image: shatterproofFilmForGlassWindows,
    characteristics: [
      "Anti-shatter technology",
      "Enhances safety and security",
      "Blocks up to 99% UV rays",
      "Reduces indoor temperatures",
      "4 Mil thickness for durability",
    ],
    variations: [
      { id: uuidv4(), color: "Clear", size: "60 x 100 FT", available: true },
      { id: uuidv4(), color: "5% Tint", size: "60 x 100 FT", available: true },
      { id: uuidv4(), color: "20% Tint", size: "60 x 100 FT", available: true },
      { id: uuidv4(), color: "35% Tint", size: "60 x 100 FT", available: false },
      { id: uuidv4(), color: "50% Tint", size: "60 x 100 FT", available: false },
    ],
    available: true,
  },
  {
    productid: "3",
    name: "Reflective Building Tint Film",
    baseprice: 175,
    description:
      "One-way mirror window film that enhances privacy and style for commercial buildings. It features a VLT mirror tint with exceptional UV blocking, perfect for offices and storefronts seeking both functionality and aesthetic appeal.",
    image: reflectiveBuildingTintFilm,
    characteristics: [
      "One-way daytime privacy",
      "Mirror-like exterior finish",
      "Blocks up to 99% UV rays",
      "Reduces heat and glare",
      "Improves building facade",
    ],
    variations: [
      { id: uuidv4(), color: "Total-Blackout", size: "36 x 25 FT", available: false },
      { id: uuidv4(), color: "Tea-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Green-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Gold-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Blue-Silver", size: "36 x 25 FT", available: true },
      { id: uuidv4(), color: "Black-Silver", size: "36 x 25 FT", available: true },
    ],
    available: true,
  },
  {
    productid: "4",
    name: "Charcoal 2-Ply Nano-Ceramic VLT Tint",
    baseprice: 220,
    description:
      "Professional-grade nano-ceramic window tint offering superior heat rejection, UV protection, and infrared radiation reduction. Ideal for commercial buildings requiring high-performance films that are easy to install and plotter-friendly.",
    image: charcoal2PlyNanoCeramicVltTint,
    characteristics: [
      "Nano-ceramic technology",
      "Blocks up to 99% UV rays",
      "Reduces infrared radiation by 85%-90%",
      "Enhances comfort",
      "Easy shrink and installation",
    ],
    variations: [
      { id: uuidv4(), color: "5% VLT", size: "40 x 100 FT", available: true },
      { id: uuidv4(), color: "20% VLT", size: "40 x 100 FT", available: true },
      { id: uuidv4(), color: "35% VLT", size: "40 x 100 FT", available: true },
      { id: uuidv4(), color: "50% VLT", size: "40 x 100 FT", available: false },
      { id: uuidv4(), color: "70% VLT", size: "40 x 100 FT", available: false },
    ],
    available: true,
  },
];
