// src/components/SeparatorLine.tsx

import React from 'react';
import { useTheme } from '../../context/ThemeContext';

const SeparatorLine = () => {
  const { darkMode } = useTheme();

  return (
    <div
      className={`w-full h-1 relative overflow-hidden ${
        darkMode
          ? 'bg-gray-900' // Fundo para dark mode
          : 'bg-white' // Fundo para light mode
      }`}
    >
      <div
        className={`absolute top-0 left-0 h-full w-full animate-gradient ${
          darkMode
            ? 'bg-gradient-to-r from-blue-500 via-turquoise-500 to-purple-500'
            : 'bg-gradient-to-r from-orange-500 via-red-500 to-yellow-500'
        }`}
        style={{
          backgroundSize: '300% 100%', // Aumenta o tamanho do gradiente para melhorar o efeito
          backgroundImage: darkMode
            ? 'linear-gradient(270deg, #1e3a8a, #3b82f6, #06b6d4, #06f, #9333ea)' // Paleta para dark mode
            : 'linear-gradient(270deg, #ff7f50, #ff4500, #ff6347, #ff8c00, #ffd700)', // Paleta para light mode
        }}
      ></div>
    </div>
  );
};

export default SeparatorLine;
