// src/pages/Contact.tsx

import React, { useState } from 'react';
import { useStyledColors } from '../hooks/useStyledColors';

const Contact: React.FC = () => {
  const { bgColor, textColor, accentBgColor, buttonPrimaryBgColor } = useStyledColors();

  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
    file: File | null;
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    file: null,
  });

  const [errors, setErrors] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    file: string;
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    file: '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      alert('Form submitted successfully!');
      // Logic to send the form data
    }
  };

  const validateForm = () => {
    const newErrors = { firstName: '', lastName: '', email: '', phone: '', file: '' };
    let valid = true;

    // Validation of First Name
    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ' -]{1,30}$/.test(formData.firstName.trim())) {
      newErrors.firstName = 'First name must contain only letters and up to 30 characters.';
      valid = false;
    }

    // Validation of Last Name
    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ' -]{1,50}$/.test(formData.lastName.trim())) {
      newErrors.lastName = 'Last name must contain only letters and up to 50 characters.';
      valid = false;
    }

    // Validation of Email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      newErrors.email = 'Please enter a valid email address.';
      valid = false;
    }

    // Validation of Phone
    const phoneDigits = formData.phone.replace(/\D/g, '');
    if (phoneDigits.length !== 10) {
      newErrors.phone = 'Phone number must be 10 digits.';
      valid = false;
    }

    // Validation of File
    if (formData.file) {
      if (formData.file.size > 10485760) {
        newErrors.file = 'File size must be less than 10MB.';
        valid = false;
      } else if (formData.file.type !== 'image/jpeg') {
        newErrors.file = 'Only JPG files are allowed.';
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Added handleFileChange function
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, file: e.target.files[0] });
    }
  };

  // Handler to restrict name input characters
  const handleNameKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  // Handler for phone input
  const handlePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = e.target.value.replace(/\D/g, '');
    setFormData({ ...formData, phone: formattedPhone });
  };

  return (
    <div className={`p-8 min-h-screen ${bgColor} ${textColor}`}>
      <h1 className="text-4xl font-bold text-center mb-8">Contact Us</h1>

      {/* Accent Line below the title */}
      <div className="flex justify-center mb-8">
        <div
          className={`h-1 w-24 rounded`}
          style={{ backgroundColor: accentBgColor }}
        ></div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto space-y-6"
        encType="multipart/form-data"
      >
        {/* First Name */}
        <div>
          <label className="block text-lg mb-2">First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            onKeyPress={handleNameKeyPress}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter your first name"
          />
          {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
        </div>

        {/* Last Name */}
        <div>
          <label className="block text-lg mb-2">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            onKeyPress={handleNameKeyPress}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter your last name"
          />
          {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
        </div>

        {/* Email */}
        <div>
          <label className="block text-lg mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter your email"
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
        </div>

        {/* Phone */}
        <div>
          <label className="block text-lg mb-2">Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handlePhoneInput}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter your phone number"
          />
          {errors.phone && <p className="text-red-500">{errors.phone}</p>}
        </div>

        {/* Message */}
        <div>
          <label className="block text-lg mb-2">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            maxLength={300}
            className="w-full p-2 border border-gray-300 rounded"
            rows={5}
            placeholder="Enter your message"
          />
        </div>

        {/* Upload File */}
        <div>
          <label className="block text-lg mb-2">
            Attach File (JPG, max 10MB)
          </label>
          <input
            type="file"
            accept="image/jpeg"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {errors.file && <p className="text-red-500">{errors.file}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={`w-full p-3 text-white font-semibold rounded-lg ${buttonPrimaryBgColor} hover:opacity-90 transition-opacity duration-300`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
