// src/components/Footer.tsx

import React from "react";
import { useTheme } from "../../context/ThemeContext";

const Footer = () => {
  const { darkMode } = useTheme();

  const bgColor = darkMode ? "bg-gray-900 text-white" : "bg-orange-600 text-white";
  const linkColor = darkMode
    ? "text-white hover:text-gray-300"
    : "text-white hover:text-gray-800"; // Ajusta a cor do link no modo escuro para branco

  const companyInfo = {
    name: "Deflect",
    address: "3330 West Cypress St, Tampa, FL 33607",
    email: "info@deflect.com",
  };

  return (
    <footer className={`p-4 transition-colors duration-300 ${bgColor}`}>
      <div className="container mx-auto text-center max-w-6xl">
        <p className="mb-2">
          &copy; {new Date().getFullYear()} {companyInfo.name}. All rights reserved.
        </p>
        <p className="mb-2">{companyInfo.address}</p>
        <p className="mb-2">
          <a
            href={`mailto:${companyInfo.email}`}
            className={`transition-colors duration-300 ${linkColor}`}
          >
            {companyInfo.email}
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
