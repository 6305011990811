// src/components/Carousel.tsx

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTheme } from '../context/ThemeContext';

// Import images from assets/images/ai
import image1 from '../assets/images/ai/1.jpg';
import image2 from '../assets/images/ai/2.jpg';
import image3 from '../assets/images/ai/3.jpg';
import image4 from '../assets/images/ai/4.jpg';
import image5 from '../assets/images/ai/5.jpg';
import image6 from '../assets/images/ai/6.jpg';
import image7 from '../assets/images/ai/7.jpg';
import image8 from '../assets/images/ai/8.jpg';
import image9 from '../assets/images/ai/9.jpg';
import image10 from '../assets/images/ai/10.jpg';
import image11 from '../assets/images/ai/11.jpg';
import image12 from '../assets/images/ai/12.jpg';
import image13 from '../assets/images/ai/13.jpg';
import image14 from '../assets/images/ai/14.jpg';
import image15 from '../assets/images/ai/15.jpg';
import image16 from '../assets/images/ai/16.jpg';
import image17 from '../assets/images/ai/17.jpg';
import image18 from '../assets/images/ai/18.jpg';
import image19 from '../assets/images/ai/19.jpg';

import btws1 from '../assets/images/ai/btws1.jpg';
import btws2 from '../assets/images/ai/btws2.jpg';
import btws3 from '../assets/images/ai/btws3.jpg';
import btws4 from '../assets/images/ai/btws4.jpg';
import btws5 from '../assets/images/ai/btws5.jpg';
import btws6 from '../assets/images/ai/btws6.jpg';
import btws7 from '../assets/images/ai/btws7.jpg';
import btws71 from '../assets/images/ai/btws7.jpeg';
import btws8 from '../assets/images/ai/btws8.jpg';
import btws9 from '../assets/images/ai/btws9.jpg';
import btws10 from '../assets/images/ai/btws10.jpg';
import btws11 from '../assets/images/ai/btws11.jpg';
import btws12 from '../assets/images/ai/btws12.jpg';
import btws13 from '../assets/images/ai/btws13.jpg';
import btws14 from '../assets/images/ai/btws14.jpg';
import btws15 from '../assets/images/ai/btws15.jpg';
import btws16 from '../assets/images/ai/btws16.jpg';
import btws17 from '../assets/images/ai/btws17.jpg';
import btws18 from '../assets/images/ai/btws18.jpg';
import btws19 from '../assets/images/ai/btws19.jpg';
import btws20 from '../assets/images/ai/btws20.jpg';
import btws21 from '../assets/images/ai/btws21.jpg';
import btws22 from '../assets/images/ai/btws22.jpg';
import btws23 from '../assets/images/ai/btws23.jpg';
import btws24 from '../assets/images/ai/btws24.jpg';

const ImageCarousel: React.FC = () => {
  const { darkMode } = useTheme();

  // Determine the accent color based on dark mode
  const accentColor = darkMode ? '#2563EB' : '#EA580C';

  const images = [
    btws1,
    image1,
    btws3,
    image2,
    image19,
    // image4,
    // image5,
    btws4,
    //image3,
    // image7,
    btws6,
    image17,
    //image8,
    // btws8,
    // image9,
    // image10,
    btws2,
    image11,
    // btws5,
    btws7,
    btws71,
    image6,
    // btws9,
    btws10,
    image15,
    // btws11,
    // btws12,
    // btws13,
    // btws14,
    btws15,
    image18,
    btws16,
    image12,
    // image15,
    // btws17,
    btws18,
    image13,
    btws19,
    // btws20,
    btws21,
    //image14,
    btws22,
    btws23,
    image16,
    btws24,
  ];

  // Define responsive breakpoints
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3, // Show 3 items on desktop
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2, // Show 2 items on tablet
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1, // Show 1 item on mobile
    },
  };

  return (
    <div className="mb-20">
      <Carousel
        responsive={responsive}
        autoPlay
        autoPlaySpeed={2000} // Increased rotation speed (3 seconds)
        infinite
        arrows={false}
        showDots={false} // Set to false to remove the dots
        containerClass="carousel-container pb-8"
        itemClass="p-0 m-0"
        renderDotsOutside={false} // Dots are not rendered, so this can be false
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
      >
        {images.map((image, index) => (
          <div key={index} className="w-full h-full">
            <div
              className="w-full h-full overflow-hidden"
              //style={{
                //borderTop: `2px solid ${accentColor}`,
                //borderBottom: `2px solid ${accentColor}`,
                //boxSizing: 'border-box',
              //}}
            >
              <img
                src={image}
                alt={`Product Image ${index + 1}`}
                className="w-full h-80 md:h-96 lg:h-80 object-cover"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
