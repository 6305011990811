// src/components/ScrollToTop.tsx

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../context/ThemeContext";

const ScrollToTop = () => {
  const { darkMode } = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      toggleVisibility();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const buttonBgColor = darkMode
    ? "bg-blue-600 hover:bg-blue-700"
    : "bg-orange-600 hover:bg-orange-700";

  return (
    <>
      {isVisible && (
        <div className="fixed bottom-8 right-8 z-50"> {/* Alinha com ContactUsButton */}
          <button
            onClick={scrollToTop}
            className={`flex items-center justify-center ${buttonBgColor} text-white h-12`} // h-12 para corresponder ao ContactUsButton
            style={{ width: "auto", padding: "0 16px" }}
            aria-label="Scroll to top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
