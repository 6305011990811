// src/components/Calculator.tsx

import React, { useEffect, useState, useRef } from "react";
import { useStyledColors } from "../hooks/useStyledColors";
import ConfirmServiceForm from "./forms/ConfirmeServiceForm"; // Ensure the path is correct
import zipCodesDataRaw from "../data/zipCodes.json";

import { freightData, FreightEntry } from "../data/freightData";
import { laborData } from "../data/laborData";
import { taxData, TaxEntry } from "../data/taxData";

interface ZipCodeEntry {
  latitude: number;
  longitude: number;
  placeName: string;
}

interface CalculatorProps {
  product: any;
  variation: any;
  onClose: () => void;
}

const zipCodesData: { [zipCode: string]: ZipCodeEntry } = zipCodesDataRaw;

const Calculator: React.FC<CalculatorProps> = ({
  product,
  variation,
  onClose,
}) => {
  const { bgColor, buttonPrimaryBgColor, accentBgColor } = useStyledColors();
  const [windowDimensions, setWindowDimensions] = useState({
    width: "",
    height: "",
  });
  const [numWindows, setNumWindows] = useState(1); // Start from 1
  const [totalCost, setTotalCost] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const [showConfirmForm, setShowConfirmForm] = useState(false);
  const [freight, setFreight] = useState<number>(0);
  const [zipCode, setZipCode] = useState<string>("");
  const modalRef = useRef<HTMLDivElement>(null);
  const [isCalculating, setIsCalculating] = useState(false);

  // States for detailed costs
  const [materialCost, setMaterialCost] = useState<number>(0);
  const [laborCostState, setLaborCostState] = useState<number>(0);
  const [taxAmount, setTaxAmount] = useState<number>(0);

  // Haversine function to calculate distance between two coordinates
  const haversineDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // Function to parse distance range from freight data
  const parseDistanceRange = (range: string) => {
    try {
      const [minStr, maxStrWithMiles] = range.split("-");
      const min = parseFloat(minStr.trim());
      const maxStr = maxStrWithMiles.trim().replace(" miles", "");
      const max = parseFloat(maxStr);
      return { min, max };
    } catch (error) {
      console.error("Error parsing distance range:", range, error);
      return { min: 0, max: 0 };
    }
  };

  const calculateArea = (width: number, height: number) => width * height; // Area in square feet

  const calculateMaterialCost = (
    basePrice: number,
    area: number,
    numWindows: number
  ) => basePrice * area * numWindows;

  const calculateLaborCost = (baseLaborCost: number, materialCost: number) =>
    baseLaborCost + materialCost * laborData.additionalLaborMultiplier;

  const calculateTaxes = (materialCost: number) => {
    const totalTaxPercentage = taxData.reduce(
      (acc: number, tax: TaxEntry) => acc + tax.rate,
      0
    );
    return materialCost * totalTaxPercentage;
  };

  const getFreightCost = async (zipCodeInput: string) => {
    const companyCoords = { lat: 27.9506, lon: -82.4572 }; // Tampa, FL coordinates

    const zipCodeNormalized = zipCodeInput.trim();

    if (!zipCodesData[zipCodeNormalized]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipCode:
          "Currently, we only serve Florida. Please enter a valid Florida ZIP code.",
      }));
      setIsCalculating(false);
      return null;
    }

    // Clear ZIP code error if valid
    setErrors((prevErrors) => ({ ...prevErrors, zipCode: null }));

    const clientCoords = {
      lat: zipCodesData[zipCodeNormalized].latitude,
      lon: zipCodesData[zipCodeNormalized].longitude,
    };

    const distanceKm = haversineDistance(
      companyCoords.lat,
      companyCoords.lon,
      clientCoords.lat,
      clientCoords.lon
    );
    const distanceMiles = distanceKm * 0.621371;

    const freightInfo = freightData.find((item: FreightEntry) => {
      const { min, max } = parseDistanceRange(item.distanceRange);
      return distanceMiles >= min && distanceMiles <= max;
    });

    const cost = freightInfo ? freightInfo.price : 0;

    return cost;
  };

  const calculateCost = async () => {
    const width = parseFloat(windowDimensions.width);
    const height = parseFloat(windowDimensions.height);

    let validationErrors: { [key: string]: string } = {};

    if (isNaN(width) || width <= 0) {
      validationErrors.width = "Width must be greater than zero.";
    }

    if (isNaN(height) || height <= 0) {
      validationErrors.height = "Height must be greater than zero.";
    }

    if (numWindows <= 0) {
      validationErrors.numWindows = "Number of windows must be at least 1.";
    }

    if (!zipCode) {
      validationErrors.zipCode = "ZIP code is required.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setTotalCost(null);
      return;
    }

    // Clear previous errors
    setErrors({});

    setIsCalculating(true);

    const area = calculateArea(width, height);

    const materialCostCalculated = calculateMaterialCost(
      product.baseprice,
      area,
      numWindows
    );

    const calculatedFreight = await getFreightCost(zipCode);

    if (calculatedFreight === null) {
      // Error was set in getFreightCost
      setTotalCost(null);
      setIsCalculating(false);
      return;
    }

    const laborCostCalculated = calculateLaborCost(
      laborData.baseLaborCost,
      materialCostCalculated
    );
    const taxAmountCalculated = calculateTaxes(materialCostCalculated);

    const totalServiceCost =
      materialCostCalculated +
      laborCostCalculated +
      taxAmountCalculated +
      calculatedFreight;

    // Set individual cost components
    setMaterialCost(materialCostCalculated);
    setLaborCostState(laborCostCalculated);
    setTaxAmount(taxAmountCalculated);
    setFreight(calculatedFreight);

    setTotalCost(totalServiceCost);
    setIsCalculating(false);
  };

  const handleProceedToConfirm = () => {
    setShowConfirmForm(true);
  };

  // Monitor clicks outside the modal to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      aria-modal="true"
      role="dialog"
      style={{ zIndex: 1000 }}
    >
      <div
        ref={modalRef}
        className={`p-2 sm:p-3 rounded-lg shadow-lg relative ${bgColor} calculator-popup w-full max-w-sm sm:max-w-md md:max-w-lg`}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute top-1 right-1 text-gray-600 hover:text-gray-900 text-lg sm:text-xl"
          aria-label="Close calculator"
        >
          &times;
        </button>

        {!showConfirmForm ? (
          <>
            <h2 className="text-base sm:text-lg mb-2 text-center font-bold">
              Cost Calculator
            </h2>
            <div
              className={`mx-auto mb-3 w-10 sm:w-12 h-0.5 rounded`}
              style={{ backgroundColor: accentBgColor }}
            ></div>
            <p className="mb-2 text-center text-xs sm:text-sm">
              Enter the window dimensions and ZIP code to calculate the total
              cost.
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                calculateCost();
              }}
              className="space-y-2"
            >
              {/* Window Width Field */}
              <div>
                <label className="block mb-1 text-sm font-medium">
                  Window Width (feet)
                </label>
                <input
                  className={`w-full p-2 border rounded-md ${
                    errors.width ? "border-red-500" : "border-gray-300"
                  }`}
                  type="number"
                  value={windowDimensions.width}
                  onChange={(e) =>
                    setWindowDimensions({
                      ...windowDimensions,
                      width: e.target.value,
                    })
                  }
                  min="0.01" // Set minimum value greater than zero
                  step="any"
                  required
                />
                {errors.width && (
                  <p className="text-red-500 text-xs mt-1">{errors.width}</p>
                )}
              </div>

              {/* Window Height Field */}
              <div>
                <label className="block mb-1 text-sm font-medium">
                  Window Height (feet)
                </label>
                <input
                  className={`w-full p-2 border rounded-md ${
                    errors.height ? "border-red-500" : "border-gray-300"
                  }`}
                  type="number"
                  value={windowDimensions.height}
                  onChange={(e) =>
                    setWindowDimensions({
                      ...windowDimensions,
                      height: e.target.value,
                    })
                  }
                  min="0.01" // Set minimum value greater than zero
                  step="any"
                  required
                />
                {errors.height && (
                  <p className="text-red-500 text-xs mt-1">{errors.height}</p>
                )}
              </div>

              {/* Number of Windows Field */}
              <div>
                <label className="block mb-1 text-sm font-medium">
                  Number of Windows
                </label>
                <input
                  className={`w-full p-2 border rounded-md ${
                    errors.numWindows ? "border-red-500" : "border-gray-300"
                  }`}
                  type="number"
                  value={numWindows}
                  onChange={(e) => setNumWindows(+e.target.value)}
                  min="1"
                  required
                />
                {errors.numWindows && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.numWindows}
                  </p>
                )}
              </div>

              {/* ZIP Code Field */}
              <div>
                <label className="block mb-1 text-sm font-medium">
                  ZIP Code
                </label>
                <input
                  className={`w-full p-2 border rounded-md ${
                    errors.zipCode ? "border-red-500" : "border-gray-300"
                  }`}
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
                {errors.zipCode && (
                  <p className="text-red-500 text-xs mt-1">{errors.zipCode}</p>
                )}
              </div>

              {/* Calculate Button */}
              <button
                type="submit"
                className={`mt-1 w-full p-1 sm:p-2 rounded ${buttonPrimaryBgColor} text-xs sm:text-sm text-white`}
                disabled={isCalculating}
              >
                {isCalculating ? "Calculating..." : "Calculate"}
              </button>
            </form>

            {totalCost !== null && (
              <div className="mt-2 text-center">
                <h3 className="font-semibold text-xs sm:text-sm">
                  Total Cost:
                </h3>
                <p className="text-sm sm:text-base">
                  ${totalCost.toFixed(2)}
                </p>
                <button
                  onClick={handleProceedToConfirm}
                  className={`mt-1 w-full p-1 sm:p-2 rounded ${buttonPrimaryBgColor} text-xs sm:text-sm text-white`}
                >
                  Confirm Service
                </button>
              </div>
            )}
          </>
        ) : (
          <ConfirmServiceForm
            product={product}
            variation={variation}
            onClose={onClose}
            totalCost={totalCost !== null ? totalCost : 0}
            windowDimensions={windowDimensions}
            numWindows={numWindows}
            freightCost={freight}
            materialCost={materialCost}
            laborCost={laborCostState}
            taxAmount={taxAmount}
          />
        )}
      </div>
    </div>
  );
};

export default Calculator;