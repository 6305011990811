// src/components/manager/ProductManagement.tsx

import React, { useEffect, useState } from 'react';
import { useProducts } from '../../context/ProductsContext';
import { Product, Variation } from '../../data/productsData';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';

// Remove theme-based styling
// import { useStyledColors } from '../../hooks/useStyledColors';

const ProductManagement: React.FC = () => {
  const { products, addProduct, updateProduct, deleteProduct } = useProducts();
  // We're not using theme colors here
  // const { accentColor } = useStyledColors();

  const [currentProduct, setCurrentProduct] = useState<Product>({
    productid: '',
    name: '',
    baseprice: NaN,
    description: '',
    image: '',
    characteristics: [],
    variations: [],
    available: true,
  });
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [newCharacteristic, setNewCharacteristic] = useState<string>('');
  const [newVariation, setNewVariation] = useState<{
    color: string;
    size: string;
    available: boolean;
  }>({ color: '', size: '', available: true });

  // State to handle validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Populate the form when a product is selected for editing
  useEffect(() => {
    if (selectedProductId) {
      const selectedProduct = products.find((p) => p.productid === selectedProductId);
      if (selectedProduct) {
        setCurrentProduct(selectedProduct);
      }
    } else {
      resetForm();
    }
    // Clear errors when switching products
    setErrors({});
  }, [selectedProductId, products]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCurrentProduct((prev) => ({
      ...prev,
      [name]: name === 'baseprice' ? parseFloat(value) : value,
    }));

    // Clear error for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleAddCharacteristic = () => {
    if (newCharacteristic.trim() === '') return;
    setCurrentProduct((prev) => ({
      ...prev,
      characteristics: [...prev.characteristics, newCharacteristic.trim()],
    }));
    setNewCharacteristic('');
  };

  const handleDeleteCharacteristic = (index: number) => {
    setCurrentProduct((prev) => ({
      ...prev,
      characteristics: prev.characteristics.filter((_, i) => i !== index),
    }));
  };

  const handleAddVariation = () => {
    const { color, size } = newVariation;
    if (color.trim() === '' || size.trim() === '') return;
    const newVar: Variation = {
      id: uuidv4(),
      color: color.trim(),
      size: size.trim(),
      available: newVariation.available,
    };
    setCurrentProduct((prev) => ({
      ...prev,
      variations: [...prev.variations, newVar],
    }));
    setNewVariation({ color: '', size: '', available: true });
  };

  const handleDeleteVariation = (id: string) => {
    setCurrentProduct((prev) => ({
      ...prev,
      variations: prev.variations.filter((variation) => variation.id !== id),
    }));
  };

  // Clear errors for variations when adding or deleting variations
  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      variations: '',
    }));
  }, [currentProduct.variations]);

  const handleAddOrUpdateProduct = () => {
    const newErrors: { [key: string]: string } = {};

    if (currentProduct.name.trim() === '') {
      newErrors.name = 'Please enter a product name.';
    }

    if (currentProduct.description.trim() === '') {
      newErrors.description = 'Please enter a product description.';
    }

    if (currentProduct.image.trim() === '') {
      newErrors.image = 'Please enter an image URL.';
    }

    if (!currentProduct.productid.trim() && !selectedProductId) {
      newErrors.productid = 'Please enter a product ID.';
    }

    if (isNaN(currentProduct.baseprice) || currentProduct.baseprice <= 0) {
      newErrors.baseprice = 'Please enter a valid base price greater than 0.';
    }

    // Check variations
    if (currentProduct.variations.length === 0) {
      newErrors.variations = 'Please add at least one variation to the product.';
    } else {
      // Ensure all variations have color and size
      const invalidVariations = currentProduct.variations.filter(
        (variation) => variation.color.trim() === '' || variation.size.trim() === ''
      );

      if (invalidVariations.length > 0) {
        newErrors.variations = 'All variations must have defined color and size.';
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const productToSave = {
      ...currentProduct,
      // Ensure all variations are valid
      variations: currentProduct.variations.filter(
        (variation) => variation.color.trim() !== '' && variation.size.trim() !== ''
      ),
    };

    if (selectedProductId) {
      // Update the existing product
      updateProduct(productToSave);
    } else {
      // Add a new product
      addProduct(productToSave);
    }
    resetForm();
    // Clear errors
    setErrors({});
  };

  const handleDeleteProduct = (productid: string) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      deleteProduct(productid);
      if (selectedProductId === productid) {
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setCurrentProduct({
      productid: '',
      name: '',
      baseprice: NaN,
      description: '',
      image: '',
      characteristics: [],
      variations: [],
      available: true,
    });
    setSelectedProductId(null);
    setNewCharacteristic('');
    setNewVariation({ color: '', size: '', available: true });
    setErrors({});
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800">
        Product Management
      </h3>

      {/* Product Form */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddOrUpdateProduct();
        }}
        className="space-y-6"
      >
        {/* Basic Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Product Name */}
          <div>
            <label htmlFor="name" className="block mb-2 font-medium text-gray-700">
              Product Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter product name"
              value={currentProduct.name}
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.name ? 'border-red-500' : ''
              }`}
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
          </div>

          {/* Base Price */}
          <div>
            <label htmlFor="baseprice" className="block mb-2 font-medium text-gray-700">
              Base Price ($)
            </label>
            <input
              type="number"
              name="baseprice"
              id="baseprice"
              placeholder="Enter base price"
              value={
                isNaN(currentProduct.baseprice) || currentProduct.baseprice === 0
                  ? ''
                  : currentProduct.baseprice
              }
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.baseprice ? 'border-red-500' : ''
              }`}
              min="0"
              step="0.01"
            />
            {errors.baseprice && (
              <p className="text-red-500 text-sm mt-1">{errors.baseprice}</p>
            )}
          </div>

          {/* Image URL */}
          <div>
            <label htmlFor="image" className="block mb-2 font-medium text-gray-700">
              Image URL
            </label>
            <input
              type="text"
              name="image"
              id="image"
              placeholder="Enter image URL"
              value={currentProduct.image}
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.image ? 'border-red-500' : ''
              }`}
            />
            {errors.image && (
              <p className="text-red-500 text-sm mt-1">{errors.image}</p>
            )}
          </div>

          {/* Product ID */}
          <div>
            <label htmlFor="productid" className="block mb-2 font-medium text-gray-700">
              Product ID
            </label>
            <input
              type="text"
              name="productid"
              id="productid"
              placeholder="Enter product ID"
              value={currentProduct.productid}
              onChange={handleInputChange}
              className={`border p-2 rounded-md w-full text-gray-800 ${
                errors.productid ? 'border-red-500' : ''
              }`}
              disabled={!!selectedProductId}
            />
            {errors.productid && (
              <p className="text-red-500 text-sm mt-1">{errors.productid}</p>
            )}
          </div>
        </div>

        {/* Description */}
        <div>
          <label htmlFor="description" className="block mb-2 font-medium text-gray-700">
            Description
          </label>
          <textarea
            name="description"
            id="description"
            placeholder="Enter product description"
            value={currentProduct.description}
            onChange={handleInputChange}
            className={`border p-2 rounded-md w-full text-gray-800 ${
              errors.description ? 'border-red-500' : ''
            }`}
            rows={4}
          ></textarea>
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>

        {/* Product Variations */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-800 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none">
                <span>Variations</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Color */}
                    <div>
                      <label htmlFor="color" className="block mb-2 font-medium text-gray-700">
                        Color
                      </label>
                      <input
                        type="text"
                        name="color"
                        id="color"
                        placeholder="Enter color"
                        value={newVariation.color}
                        onChange={(e) =>
                          setNewVariation((prev) => ({ ...prev, color: e.target.value }))
                        }
                        className="border p-2 rounded-md w-full text-gray-800"
                      />
                    </div>

                    {/* Size */}
                    <div>
                      <label htmlFor="size" className="block mb-2 font-medium text-gray-700">
                        Size
                      </label>
                      <input
                        type="text"
                        name="size"
                        id="size"
                        placeholder="Enter size"
                        value={newVariation.size}
                        onChange={(e) =>
                          setNewVariation((prev) => ({ ...prev, size: e.target.value }))
                        }
                        className="border p-2 rounded-md w-full text-gray-800"
                      />
                    </div>

                    {/* Availability */}
                    <div className="flex items-center mt-6 md:mt-0">
                      <input
                        type="checkbox"
                        checked={newVariation.available}
                        onChange={(e) =>
                          setNewVariation((prev) => ({
                            ...prev,
                            available: e.target.checked,
                          }))
                        }
                        className="form-checkbox h-5 w-5 text-blue-600"
                        id="variation-available"
                      />
                      <label htmlFor="variation-available" className="ml-2 text-gray-800">
                        Available
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={handleAddVariation}
                    className="bg-green-500 text-white p-2 rounded"
                  >
                    Add Variation
                  </button>
                  <ul className="space-y-2">
                    {currentProduct.variations.map((variation) => (
                      <li
                        key={variation.id}
                        className={`flex justify-between items-center border p-2 rounded-md ${
                          (variation.color.trim() === '' || variation.size.trim() === '')
                            ? 'border-red-500'
                            : ''
                        }`}
                      >
                        <div className="text-gray-800">
                          <p>
                            <strong>Color:</strong> {variation.color}
                          </p>
                          <p>
                            <strong>Size:</strong> {variation.size}
                          </p>
                          <p>
                            <strong>Available:</strong> {variation.available ? 'Yes' : 'No'}
                          </p>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleDeleteVariation(variation.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Display error message for variations */}
                {errors.variations && (
                  <p className="text-red-500 text-sm mt-1">{errors.variations}</p>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* Product Availability */}
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={currentProduct.available}
            onChange={(e) =>
              setCurrentProduct((prev) => ({ ...prev, available: e.target.checked }))
            }
            className="form-checkbox h-5 w-5 text-green-600"
            id="product-available"
          />
          <label htmlFor="product-available" className="ml-2 text-gray-800">
            Available to Customers
          </label>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center md:justify-end space-x-4">
          <button
            type="submit"
            className="bg-green-500 text-white px-6 py-2 rounded"
          >
            {selectedProductId ? 'Update Product' : 'Add Product'}
          </button>
          {selectedProductId && (
            <button
              type="button"
              onClick={resetForm}
              className="bg-gray-500 text-white px-6 py-2 rounded"
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Existing Products List */}
      <div className="mt-12">
        <h4 className="text-xl font-semibold mb-6 text-center text-gray-800">
          Existing Products Entries
        </h4>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-gray-800">ID</th>
                <th className="py-2 px-4 border-b text-gray-800">Name</th>
                <th className="py-2 px-4 border-b text-gray-800">Price ($)</th>
                <th className="py-2 px-4 border-b text-gray-800">Available</th>
                <th className="py-2 px-4 border-b text-gray-800">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.productid} className="text-center">
                  <td className="py-2 px-4 border-b text-gray-800">{product.productid}</td>
                  <td className="py-2 px-4 border-b text-gray-800">{product.name}</td>
                  <td className="py-2 px-4 border-b text-gray-800">
                    ${product.baseprice.toFixed(2)}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {product.available ? (
                      <span className="text-green-500">Yes</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b space-x-2">
                    <button
                      onClick={() => setSelectedProductId(product.productid)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product.productid)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              {products.length === 0 && (
                <tr>
                  <td colSpan={5} className="py-4 text-center text-gray-500">
                    No products available at the moment.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Removed ToastContainer */}
      {/* Notifications Container */}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ProductManagement;
