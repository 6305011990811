// src/data/freightData.ts
export interface FreightEntry {
  id: number;
  distanceRange: string; // e.g., "0-10 miles"
  price: number;         // e.g., 20
}

export const freightData: FreightEntry[] = [
  { id: 1, distanceRange: "0-20 miles", price: 20 },
  { id: 2, distanceRange: "21-40 miles", price: 40 },
  { id: 3, distanceRange: "41-60 miles", price: 60 },
  { id: 4, distanceRange: "61-80 miles", price: 80 },
  { id: 5, distanceRange: "81-100 miles", price: 100 },
  { id: 6, distanceRange: "101-120 miles", price: 120 },
  { id: 7, distanceRange: "121-140 miles", price: 140 },
  { id: 8, distanceRange: "141-160 miles", price: 160 },
  { id: 9, distanceRange: "161-180 miles", price: 180 },
  { id:10, distanceRange: "181-200 miles", price: 200 },
  { id:11, distanceRange: "201-220 miles", price: 220 },
  // ... more entries
];
