// src/data/laborData.ts

export interface LaborData {
  id: number;
  baseLaborCost: number;
  additionalLaborMultiplier: number;
}

export const laborData: LaborData = {
  id: 1,
  baseLaborCost: 50,
  additionalLaborMultiplier: 1.2,
};
