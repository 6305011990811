// src/components/layout/Header.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoLight from '../../assets/images/deflectwtflogo.png';
import logoDark from '../../assets/images/deflectwtflogo2.png';
import { useTheme } from '../../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  isAuthenticated: boolean;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ isAuthenticated, onLogout }) => {
  const { darkMode } = useTheme();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Links de navegação públicos
  const publicNavLinks = [
    { to: '/', label: 'HOME' },
    { to: '/services', label: 'SERVICES' },
    { to: '/about', label: 'ABOUT' },
  ];

  // Links de navegação privados
  const privateNavLinks = [{ to: '/manager', label: 'MANAGER' }];

  return (
    <header
      className={`px-4 py-4 transition-colors duration-300 ${
        darkMode ? 'bg-gray-900 text-white opacity-90' : 'bg-white text-black'
      } md:flex md:items-center md:justify-between`}
    >
      {/* Logo */}
      <div className="flex justify-center mb-4 md:mb-0 md:justify-start">
        <Link to="/">
          <img
            src={darkMode ? logoDark : logoLight}
            alt="Deflect Logo"
            className="w-32 h-auto"
          />
        </Link>
      </div>

      {/* Menu de navegação */}
      <nav className="hidden md:flex flex-1 justify-center">
        <ul className="flex space-x-8 items-center">
          {/* Links de navegação públicos */}
          {publicNavLinks.map((link) => (
            <li key={link.to}>
              <Link
                to={link.to}
                className={`font-semibold transition-colors duration-300 relative group ${
                  darkMode
                    ? 'text-white hover:text-blue-500'
                    : 'text-orange-600 hover:text-amber-300'
                }`}
              >
                {link.label}
              </Link>
            </li>
          ))}

          {/* Links de navegação privados */}
          {isAuthenticated &&
            privateNavLinks.map((link) => (
              <li key={link.to}>
                <Link
                  to={link.to}
                  className={`font-semibold transition-colors duration-300 relative group ${
                    darkMode
                      ? 'text-white hover:text-blue-500'
                      : 'text-orange-600 hover:text-amber-300'
                  }`}
                >
                  {link.label}
                </Link>
              </li>
            ))}

          {/* Link de Login ou Logout */}
          {!isAuthenticated ? (
            <li>
              <Link
                to="/login"
                className={`font-semibold transition-colors duration-300 relative group ${
                  darkMode
                    ? 'text-blue-500 hover:text-blue-700'
                    : 'text-orange-600 hover:text-amber-300'
                }`}
              >
                LOGIN
              </Link>
            </li>
          ) : (
            <li>
              <button
                onClick={onLogout}
                aria-label="Logout"
                className={`font-semibold transition-colors duration-300 relative group ${
                  darkMode
                    ? 'text-red-500 hover:text-red-700'
                    : 'text-red-600 hover:text-red-800'
                }`}
              >
                LOGOUT
              </button>
            </li>
          )}
        </ul>
      </nav>

      {/* Botão "Contact" */}
      <div className="hidden md:flex md:justify-end">
        <Link to="/contact">
          <button
            className={`px-5 py-2 font-semibold transition-all duration-300 transform ${
              darkMode
                ? 'bg-gradient-to-r from-blue-400 via-turquoise-500 to-blue-800 text-white'
                : 'bg-gradient-to-r from-orange-500 via-red-500 to-yellow-400 text-white'
            } hover:scale-105`}
          >
            CONTACT
          </button>
        </Link>
      </div>

      {/* Botão de menu hambúrguer - visível no mobile */}
      <div className="flex justify-center md:hidden mb-4">
        <button
          className="text-2xl focus:outline-none"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          aria-label="Toggle navigation menu"
        >
          <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Menu de navegação e botão "Contact" no mobile */}
      {mobileMenuOpen && (
        <div className="w-full md:hidden">
          {/* Menu de navegação */}
          <nav className="mb-4">
            <ul className="flex flex-col items-center">
              {/* Links de navegação públicos */}
              {publicNavLinks.map((link) => (
                <li key={link.to} className="mb-2">
                  <Link
                    to={link.to}
                    className={`font-semibold transition-colors duration-300 relative group ${
                      darkMode
                        ? 'text-white hover:text-blue-500'
                        : 'text-orange-600 hover:text-amber-300'
                    }`}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}

              {/* Links de navegação privados */}
              {isAuthenticated &&
                privateNavLinks.map((link) => (
                  <li key={link.to} className="mb-2">
                    <Link
                      to={link.to}
                      className={`font-semibold transition-colors duration-300 relative group ${
                        darkMode
                          ? 'text-white hover:text-blue-500'
                          : 'text-orange-600 hover:text-amber-300'
                      }`}
                    onClick={() => setMobileMenuOpen(false)}
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}

              {/* Link de Login ou Logout */}
              {!isAuthenticated ? (
                <li>
                  <Link
                    to="/login"
                    className={`font-semibold transition-colors duration-300 relative group ${
                      darkMode
                        ? 'text-blue-500 hover:text-blue-700'
                        : 'text-orange-600 hover:text-amber-300'
                    }`}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    LOGIN
                  </Link>
                </li>
              ) : (
                <li>
                  <button
                    onClick={() => {
                      onLogout();
                      setMobileMenuOpen(false);
                    }}
                    aria-label="Logout"
                    className={`font-semibold transition-colors duration-300 relative group ${
                      darkMode
                        ? 'text-red-500 hover:text-red-700'
                        : 'text-red-600 hover:text-red-800'
                    }`}
                  >
                    LOGOUT
                  </button>
                </li>
              )}
            </ul>
          </nav>

          {/* Botão Contact */}
          <div className="flex justify-center">
            <Link to="/contact" onClick={() => setMobileMenuOpen(false)}>
              <button
                className={`px-5 py-2 font-semibold transition-all duration-300 transform ${
                  darkMode
                    ? 'bg-gradient-to-r from-blue-400 via-turquoise-500 to-blue-800 text-white'
                    : 'bg-gradient-to-r from-orange-500 via-red-500 to-yellow-400 text-white'
                } hover:scale-105`}
              >
                CONTACT
              </button>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
