// src/components/product/ProductCard.tsx

import React, { useState, useRef } from 'react';
import { useStyledColors } from '../../hooks/useStyledColors';
import Calculator from '../Calculator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Variation, Product } from '../../data/productsData';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { accentColor } = useStyledColors();
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [colorError, setColorError] = useState<string>('');

  // Reference to the select element
  const selectRef = useRef<HTMLSelectElement>(null);

  // State to store the previously focused element
  const previouslyFocusedElement = useRef<Element | null>(null);

  const handleColorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedColor(e.target.value);
    setColorError('');
  };

  const handleCalculate = () => {
    if (!selectedColor) {
      setColorError('Please select a color before proceeding.');
      return;
    }

    // Remove focus from the select element to close the dropdown
    if (selectRef.current) {
      selectRef.current.blur();
    }

    // Save the currently focused element
    previouslyFocusedElement.current = document.activeElement;

    setShowCalculator(true);
  };

  const handleCloseCalculator = () => {
    setShowCalculator(false);

    // Restore focus to the previously focused element
    if (previouslyFocusedElement.current instanceof HTMLElement) {
      previouslyFocusedElement.current.focus();
    }

    // Optionally remove focus from the select element to ensure it is not focused
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  // Filter available variations
  const availableVariations = product.variations.filter(
    (variation) => variation.available
  );

  // Get unique colors from available variations
  const colors = Array.from(
    new Set(availableVariations.map((variation) => variation.color))
  );

  // Find the selected variation based on the selected color
  const selectedVariation = availableVariations.find(
    (variation) => variation.color === selectedColor
  );

  // If the product is not available, do not render the card
  if (!product.available) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col border overflow-hidden bg-white m-4 shadow-lg rounded-lg p-6">
        {/* Product Content */}
        <div className="flex-grow flex flex-col">
          <h2 className="text-2xl font-bold mb-4 text-black text-center">
            {product.name}
          </h2>
          <p className="text-black mb-4" style={{ textAlign: 'justify' }}>
            {product.description}
          </p>

          {/* Features and Color Selection */}
          <div className="mt-auto">
            {/* Features */}
            <div className="mb-4">
              <h3 className="font-medium text-black mb-2">Features:</h3>
              <ul className="space-y-2">
                {product.characteristics.map((feature, index) => (
                  <li key={index} className="flex items-start text-black">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="w-4 h-4 mt-1 mr-2"
                      style={{ color: accentColor }}
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            {/* Color Selection */}
            <div className="mb-4">
              <label className="block mb-2 font-medium text-black">
                Select Color:
              </label>
              <select
                ref={selectRef}
                className={`w-full p-2 border rounded-md text-black bg-gray-100 ${
                  colorError ? 'border-red-500' : 'border-gray-300'
                }`}
                onChange={handleColorChange}
                value={selectedColor}
              >
                <option value="" disabled>
                  Select a color...
                </option>
                {colors.map((color, index) => (
                  <option key={index} value={color}>
                    {color}
                  </option>
                ))}
              </select>
              {/* Display error message below the color selection */}
              {colorError && (
                <p className="text-red-500 text-sm mt-1">{colorError}</p>
              )}
            </div>
          </div>
        </div>

        {/* Centered "Calculate Service" Button */}
        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={handleCalculate}
            aria-label={`Calculate service for ${product.name}`}
            className={`px-6 py-2 border font-semibold rounded-md transition-colors duration-300 ${
              accentColor === '#EA580C'
                ? 'border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                : 'border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white'
            }`}
          >
            Calculate Service
          </button>
        </div>
      </div>

      {/* Calculator Modal */}
      {showCalculator && selectedVariation && (
        <Calculator
          product={product}
          variation={selectedVariation}
          onClose={handleCloseCalculator}
        />
      )}
    </>
  );
};

export default ProductCard;
