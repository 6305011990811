// src/pages/About.tsx
// src/pages/About.tsx

import React from "react";
import { useStyledColors } from "../hooks/useStyledColors";

const About: React.FC = () => {
  const { bgColor, textColor, accentBgColor } = useStyledColors();

  return (
    <div className={`p-8 min-h-screen ${bgColor} ${textColor}`}>
      {/* Título "About Deflect" com accent color */}
      <h1
        className="text-4xl font-bold text-center mb-8"
      >
        About Deflect
      </h1>

      {/* Linha abaixo do título */}
      <div
        className="w-24 h-1 mx-auto mb-8 rounded"
        style={{ backgroundColor: accentBgColor }}
      ></div>

      <div className="max-w-4xl mx-auto text-lg leading-relaxed">
        <p className="mb-6 text-justify">
          <strong>Deflect</strong> is a leading company in the installation of
          window films for commercial and business properties. Our products
          range from solar protection films, security films, to
          privacy-enhancing solutions. Our goal is to make life easier for our
          customers by offering premium-quality films that cater to every need.
        </p>
        <p className="mb-6 text-justify">
          What sets <strong>Deflect</strong> apart is our transparent pricing
          model. With us, you can easily calculate the cost of your service
          without any surprises. Simply choose your desired product and input
          the dimensions of your project into our easy-to-use calculator. You’ll
          receive an instant price quote, including labor, materials, and
          installation costs.
        </p>
        <p className="mb-6 text-justify">
          We believe in offering fair pricing and premium-quality films
          throughout Florida. Our films not only enhance the aesthetics of your
          space but also offer superior protection against harmful UV rays,
          heat, and break-ins. With our team of experts, you can rest assured
          that your installation will be completed with precision and care. Let{" "}
          <strong>Deflect</strong> be your partner in safeguarding your business
          environment with reliable and efficient window film solutions.
        </p>
      </div>
    </div>
  );
};

export default About;
