// src/components/ConfirmServiceForm.tsx

import React, { useState } from 'react';
import { useStyledColors } from '../../hooks/useStyledColors';
import axios from 'axios';

interface ConfirmServiceFormProps {
  onClose: () => void;
  totalCost: number;
  product: any;
  variation: any;
  windowDimensions: { width: string; height: string };
  numWindows: number;
  freightCost: number;
  materialCost: number;
  laborCost: number;
  taxAmount: number;
}

const ConfirmServiceForm: React.FC<ConfirmServiceFormProps> = ({
  onClose,
  totalCost,
  product,
  variation,
  windowDimensions,
  numWindows,
  freightCost,
  materialCost,
  laborCost,
  taxAmount,
}) => {
  const {
    inputBgColor,
    buttonPrimaryBgColor,
    accentColor,
    accentBgColor,
  } = useStyledColors();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Send form data and cost details to the backend
      await axios.post('http://localhost:3000/api/send-email', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        totalCost: totalCost,
        product,
        variation,
        windowDimensions,
        numWindows,
        freightCost: freightCost,
        materialCost: materialCost,
        laborCost: laborCost,
        taxAmount: taxAmount,
      });

      alert(
        `Your service request has been sent!\n` +
        `Material Cost: $${materialCost.toFixed(2)}\n` +
        `Labor Cost: $${laborCost.toFixed(2)}\n` +
        `Freight Cost: $${freightCost.toFixed(2)}\n` +
        `Tax Amount: $${taxAmount.toFixed(2)}\n` +
        `Total Cost: $${totalCost.toFixed(2)}`
      );
      onClose(); // Close the form
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send service request. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
      <form
        onSubmit={handleSubmit}
        className="space-y-1 p-1 bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md"
      >
        {/* Title with accent color */}
        <h2 className="text-center text-base sm:text-lg font-bold">Confirm Service</h2>
        <div
          className="h-0.5 w-10 sm:w-12 mx-auto rounded"
          style={{ backgroundColor: accentBgColor }}
        ></div>

        {/* Service Details Section */}
        <div className="text-center mb-1.5 text-xs sm:text-sm" style={{ color: accentColor }}>
          <strong>Service Details:</strong>
          <div className="mt-0.5">
            <p className="flex justify-between">
              <span>Material:</span>
              <span className="font-semibold">${materialCost.toFixed(2)}</span>
            </p>
            <p className="flex justify-between">
              <span>Labor:</span>
              <span className="font-semibold">${laborCost.toFixed(2)}</span>
            </p>
            <p className="flex justify-between">
              <span>Freight:</span>
              <span className="font-semibold">${freightCost.toFixed(2)}</span>
            </p>
            <p className="flex justify-between">
              <span>Tax:</span>
              <span className="font-semibold">${taxAmount.toFixed(2)}</span>
            </p>
            <p className="mt-1 font-bold text-sm sm:text-base flex justify-between">
              <span>Total:</span>
              <span className="text-sm sm:text-base">${totalCost.toFixed(2)}</span>
            </p>
          </div>
        </div>

        {/* Form Fields */}
        <div>
          <label className="block mb-0.5 text-xs sm:text-sm font-medium">First Name</label>
          <input
            type="text"
            value={formData.firstName}
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
            className={`w-full p-1 sm:p-1.5 border rounded ${inputBgColor} text-xs sm:text-sm`}
            maxLength={50}
            required
            placeholder="Your first name"
          />
        </div>

        <div>
          <label className="block mb-0.5 text-xs sm:text-sm font-medium">Last Name</label>
          <input
            type="text"
            value={formData.lastName}
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
            className={`w-full p-1 sm:p-1.5 border rounded ${inputBgColor} text-xs sm:text-sm`}
            maxLength={50}
            required
            placeholder="Your last name"
          />
        </div>

        <div>
          <label className="block mb-0.5 text-xs sm:text-sm font-medium">Email</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className={`w-full p-1 sm:p-1.5 border rounded ${inputBgColor} text-xs sm:text-sm`}
            maxLength={80}
            required
            placeholder="you@example.com"
          />
        </div>

        <div>
          <label className="block mb-0.5 text-xs sm:text-sm font-medium">Phone</label>
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) =>
              setFormData({ ...formData, phone: e.target.value })
            }
            className={`w-full p-1 sm:p-1.5 border rounded ${inputBgColor} text-xs sm:text-sm`}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="123-456-7890"
            required
          />
        </div>

        <div>
          <label className="block mb-0.5 text-xs sm:text-sm font-medium">Message</label>
          <textarea
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            className={`w-full p-1 sm:p-1.5 border rounded ${inputBgColor} text-xs sm:text-sm`}
            rows={2}
            maxLength={300}
            placeholder="Your message (optional)"
          />
        </div>

        {/* Confirmation Button */}
        <div className="text-center">
          <button
            type="submit"
            className={`w-full py-1 sm:py-1.5 px-2 sm:px-3 font-semibold text-white rounded ${buttonPrimaryBgColor} text-xs sm:text-sm`}
          >
            Confirm Service
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfirmServiceForm;
